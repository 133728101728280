require('./plugins/contentful-paint');

document.addEventListener('DOMContentLoaded', function () {
    // common
    require('./common');

    if (document.querySelector('main.template-home')) {
        import('./pages/home.js');
    }
    if (document.querySelector('main.template-programacio')) {
        import('./pages/programacio.js');
    }
    if (document.querySelector('main.template-single')) {
        import('./pages/single.js');
    }
    if (document.querySelector('main.template-page-tdb')) {
        import('./pages/page-tdb.js');
    }
});
