import('./plugins/menu');
import('./plugins/popup');

if (document.querySelector('.js-mouseanimate-trigger')) {
    import('./plugins/mouseanimate');
}

// header color updated
import('./plugins/header-color-update');

// newsletter submit check acceptance
if (document.getElementById('mc-embedded-subscribe-form')) {
    import('./plugins/newsletter-acceptance');
}

// read more
if (document.querySelector('.js-readmore')) {
    import('./plugins/read-more');
}

// sub menu
if (document.querySelector('.js-submenu')) {
    import('./plugins/sub-menu-update');
}

// historic slider
if (document.querySelector('.historic')) {
    import('./plugins/historic-slider');
}

// load Swiper
import('./vendors/swiper');